
<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-08 15:08:19
-->
<template>
  <div>
    <div class="title">
      <ListTitle :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh"></ListTitle>
    </div>
    <p class="hr"></p>
    <div>
      <div class="ofw contentsy">
        <div v-for="(item, index) in list" :key="index">
          <div class="fx htd" @click="show(item.articleId, index)">
            <div class="image mt10">
              <img class="image" :src="item.imgUrl || require('@/assets/logo1.png')" alt="">
            </div>
            <div class="date ">
              <p class="ys">{{ dateFormat(item.createdat, 'MM-dd') }}</p>
              <p class="ys1">{{ dateFormat(item.createdat, 'yyyy') }}</p>
              <p class="ys2">{{ item.authorName }}</p>

              <p class="brder"></p>
            </div>
            <div class="row">
              <div class="row_1">{{ item.articleName.length > 25 ? item.articleName.slice(0, 22) + '...' :
                item.articleName
              }}</div>
              <div class="row_2" v-html="item.content"></div>
            </div>
            <div v-if="show3 == index" class="adds">-</div>
            <div v-else class="adds">+</div>

          </div>
          <el-collapse-transition>
            <div v-show="show3 == index">
              <div class="main flex">
                <div class="file" v-for="(val, ins) in item.nodeVoList" :key="ins">
                  <span>附件{{ ins + 1 }}：</span>
                  <span @click="dldFile(val.noteId)" class="filecolor pointer">{{ val.nodeName }}</span>
                </div>
              </div>
              <div v-html="item.content"></div>
            </div>
          </el-collapse-transition>

        </div>
        <div class="prompt" v-if="list.length == 0">
          {{ isEnglish ? 'NO DATA' : '暂无数据' }}
        </div>
      </div>
    </div>
    <div class="fx jct">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage1" :page-size="6" prev-text="Prev" next-text="Next" layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import ListTitle from "@/components/reuse/listTitle";
import "@/css/elememtUi.less";
import { articleListByPage, articleDetail } from '@/api'
import dateFormat from '@/utils/time'
import '@/css/content.less'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      show3: 0,
      dateFormat,
      headerNmae: '<p style="color: #57802b;">News</p>',
      headerNmaeCh:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 35px;">新闻动态</p>',
      list: [],
      content: '',
      flag: false,
      currentPage1: 1,
      pages: {
        page: 1,
        limit: 6
      },
      total: 0,
      dateY: dateFormat(+new Date(), 'yyyy')
    };
  },
  computed: {
    ...mapGetters(['isEnglish'])
  },
  mounted() {

    this.hub.$emit('theCover', this.$route.meta.flagNam)
    // this.dateY = this.$route.query.dateY
    this.hub.$emit('scrollToz')

    this.hub.$on('dateY', () => {
      this.dateY = this.$route.query.dateY
      this.articleListByPage()
    })
    console.log('id' in this.$route.query);
    console.log(this.$route.query);
    if ('id' in this.$route.query) {
      this.findS(this.$route.query.id)
    } else {
      this.articleListByPage()
    }
  },
  methods: {
    articleListByPage() {
      // console.log(this.dateY,' =====____');
      const params = {
        ...this.pages,
        catCode: "000002%7C000012",
        year: this.dateY,
        isEn: this.isEnglish ? 1 : 0
      }
      articleListByPage(params).then((res) => {
        console.log(res.data);
        this.list = res.data.list
        this.total = res.data.total
        if (this.list.length >= 1) {
          let _articleId = this.list[0].articleId
          this.list = this.list.map((item, i) => {
            if (0 == i && item.articleId == _articleId) {
              const params1 = {
                articleId: _articleId,
              };
              this.loading = true;
              articleDetail(params1).then((res) => {
                this.loading = false;
                item.nodeVoList = res.data.nodeVoList || [];
              });
            }
            return item
          })
        }

      })
    },
    dldFile(id) {
      window.location.href =
        `http://gsc.see.org.cn/file/file/api/download?route=file&nodeId=` + id;
    },
    show(id, index) {
      if (index == this.show3) {
        this.show3 = -1
        return
      }
      this.show3 = index
      this.list = this.list.map((item, i) => {
        if (index == i && item.articleId == id) {
          const params1 = {
            articleId: id,
            isEn: this.isEnglish ? 1 : 0
          };
          this.loading = true;
          articleDetail(params1).then((res) => {
            this.loading = false;
            item.nodeVoList = res.data.nodeVoList || [];
            // this.list = [res.data]
            // this.content =
          });
        }
        return item
      })

    },
    findS(code) {
      // console.log(code);
      const params = {
        articleId: code,
        isEn: this.isEnglish ? 1 : 0
      }
      articleDetail(params).then((res) => {
        // console.log(res.data);
        this.list = [res.data]
        this.hub.$emit('dayeYYYY', dateFormat(res.data.createdat, 'yyyy'))
        // this.content = 
      })
      //   this.list = [1]
      //   this.flag = true
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages.page = val
      this.articleListByPage()
    }
  },
  components: {
    ListTitle
  }
};
</script>
<style lang="less" scoped>
.title {
  margin-top: 30px;
}

.prompt {
  text-align: center;
  line-height: 80px;
  font-size: 22px;
  color: #666;
}

.hr {
  margin-top: 34px;
  margin-right: 22px;
  margin-bottom: 10px;
  border-top: 2px solid #57802b;
}

.fx {
  display: flex;
}

.jct {
  padding-top: 20px;
  height: 60px;
  justify-content: right;
  margin-right: 30px;
}

.htd {
  height: 230px;
  width: 98%;
  border-bottom: 1px solid #b4c56a;
}

.image {
  width: 312px;
  height: 180px;
  background: #f3f3f347;
}

.mt10 {
  margin-top: 20px;
}

.date {
  margin-top: 20px;
  padding-left: 20px;

  width: 180px;

  position: relative;

  .ys {
    margin-top: 6px;
    color: #57802b;
    font-size: 30px;
    font-weight: 700;
  }

  .ys1 {
    color: #cfd0d0;
    font-size: 16px;
  }

  .ys2 {
    position: absolute;
    top: 135px;
    left: 26px;
    color: #6f7070;
    font-size: 18px;
  }

  .brder {
    width: 20px;
    height: 40px;
    position: absolute;
    top: 75px;
    left: 26px;
    border-bottom: 1px solid #cfd0d0;
  }
}

.row {
  flex: 1;
  width: 520px;

  //   color: #8c8887;
}

.row_1 {
  margin-top: 10px;
  width: 100%;
  //   padding-left: 35px;
  line-height: 60px;
  font-size: 20px;
  font-weight: bold;
}

.row_2 {
  height: 118px;
  width: 100%;
  padding-right: 20px;

  overflow: hidden;
  color: #8e8d8e;
}

.row_3 {
  height: 118px;
  width: 100%;
  padding-right: 20px;

  overflow: hidden;
  color: #8e8d8e;
}

.adds {
  margin-top: 15px;
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border: 1px solid #b4c56a;
  border-radius: 50%;
  color: #b4c56a;
  position: relative;
  top: 20px;
  left: -8px;
}

.ofw {
  width: 1048px;
  // height: 750px;
  // overflow: auto;
}

.main {
  min-height: 20px;

  color: #5e5d5d;

  .file {
    margin: 10px;
    width: 30%;
  }

  .filecolor {
    color: #337ab7;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

// .contentsy {
//     color: #5e5d5d;
//     font-size: 18px;
//     p {
//         span {
//             font-size: 18px !important;
//             text-indent: 2em !important;
//         }
//     }
// }
</style>
